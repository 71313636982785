import { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import NotFound from "../../assets/images/img/no-image-available.png";

import { IoLocationOutline } from "react-icons/io5";
import { RiArrowRightLine } from "react-icons/ri";
import { fetchOurProperties } from "../../services/profileServices";
import { Link } from "react-router-dom";
import { DynamicRoutes } from "../../constants/routes";
import { ProfileContext } from "../../contexts/ProfileContext";
import { getValue } from "../../utils/object";

const Properties = ({ translation }) => {
  const { properties, setProperties } = useContext(ProfileContext);

  const fetchData = useCallback(async () => {
    try {
      if (!properties) {
        const data = await fetchOurProperties();
        setProperties(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (properties?.length < 1) return <></>

  return (
    <div className="profile_article">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{getValue(translation, "ourProperties")}</h2>
        {properties?.length > 1 && (
          <div className="d-flex align-items-center">
            <Link to={DynamicRoutes.ViewallProperties}>
              <h5>{getValue(translation, "viewAll")}</h5>
            </Link>
            <RiArrowRightLine
              style={{ fontSize: "20px" }}
              className="text-dark cursor_pointer"
            />
          </div>
        )}
      </div>
      <article className=" mt-3 d-flex ">
        {properties?.map((property, index) => (
          <Link
            to={DynamicRoutes.ViewallProperties}
            key={index}
            className={`properties_card  `}
          >
            <img
              src={property?.locationImage ?? NotFound}
              alt={property?.name}
              className=""
            />
            <h4 className="my-2 mx-2">{property?.title}</h4>
            <p className="mb-2 mx-2 d-flex align-items-center">
              <IoLocationOutline />
              <span className="ml-1">{property?.location}</span>
            </p>
          </Link>
        ))}
      </article>
    </div>
  );
};

Properties.propTypes = {
  translation: PropTypes.object.isRequired,
};

export default Properties;
